import * as yup from "yup";

export const addCariSchemaTc = yup.object({
  CariUnvan: yup.string().required("Cari ünvan Alanı Boş Geçilemez."),
  YetkiliAdSoyadBir: yup
    .string()
    .required("Yetkili Ad-Soyad(1) Alanı Boş Geçilemez"),
  YetkiliAdSoyadIki: yup.string(),
  VergiDairesi: yup.string(),
  TcOrVergiNo: yup
    .string()
    .min(10, "Tc en az 10 karakter Olmalı.")
    .max(11, "Vergi No en fazla 11 karakter olmalı"),
  YetkiliTelefonBir: yup
    .string()
    .required("Yetkili Telefon(1) ALanı Boş Geçilemez"),
  YetkiliTelefonIki: yup.string(),
  YetkiliEmailBir: yup
    .string()
    .email("Geçerli Bir E-posta adresi giriniz!")
    .required("Yetkili(1) Mail Alanı Boş Geçilemez!"),
  YetkiliEmailIki: yup.string().email("Geçerli Bir E-posta adresi giriniz!"),
  CariTelefon: yup.string().required("Cari Telefon Alanı Boş Geçilemez"),
  Adres: yup.string().min(8, "Adres en az 8 karakter Olmalı."),
});

// export const addCariSchemaVergiNo = yup.object({
//   CariUnvan: yup.string().required("Cari ünvan Alanı Boş Geçilemez."),
//   YetkiliAdSoyadBir: yup
//     .string()
//     .required("Yetkili Ad-Soyad(1) Alanı Boş Geçilemez"),
//   YetkiliAdSoyadIki: yup.string(),

//   VergiDairesi: yup.string(),
//   VergiNo: yup.string().min(10, "VergiNo en az 8 karakter Olmalı."),
//   YetkiliTelefonBir: yup
//     .string()
//     .required("Yetkili Telefon(1) ALanı Boş Geçilemez"),
//   YetkiliTelefonIki: yup.string(),
//   YetkiliEmailBir: yup
//     .string()
//     .email("Geçerli Bir E-posta adresi giriniz!")
//     .required("Yetkili(1) Mail Alanı Boş Geçilemez!"),
//   YetkiliEmailIki: yup.string().email("Geçerli Bir E-posta adresi giriniz!"),
//   CariTelefon: yup.string().required("Cari Telefon Alanı Boş Geçilemez"),
//   Adres: yup
//     .string()
//     .min(8, "Adres en az 8 karakter Olmalı.")
//     .required("Adres Alanı Boş Geçilemez"),
// });

export const addSubeSchema = yup.object({
  SubeAdi: yup.string().required("Şube Adı Alanı Boş Geçilemez"),
  VergiDairesi: yup.string().required("Vergi Dairesi Alanı Boş Geçilemez"),
  VergiNo: yup
    .string()
    .required("Vergi No Alanı Boş Geçilemez")
    .min(10, "VergiNo en az 8 karakter Olmalı.")
    .max(10, "VergiNo en fazla 10 karakter olmalı."),

  Adres: yup.string().min(8, "Adres en az 8 karakter Olmalı."),
  Telefon: yup.string().required("Telefon ALanı Boş Geçilemez"),
  Email: yup
    .string()
    .email("Geçerli Bir E-posta adresi giriniz!")
    .required("E-posta Alanı Boş Geçilemez"),
  YetkiliAd: yup.string().required("Yetkili Ad Alanı Boş Geçilemez."),
  YetkiliGsm: yup.string().required("Yetkili GSM Alanı Boş Geçilemez"),
});

export const addMarkaSchema = yup.object({
  MarkaAdi: yup.string().required("Marka Adı Alanı Boş Geçilemez."),
  UreticiFirma: yup.string().required("Üretici Firma Alanı Boş Geçilemez."),
});

export const addModelSchema = yup.object({
  ModelAdi: yup.string().required("Model Adı Alanı Boş Geçilemez."),
});

export const addParcaSchema = yup.object({
  ParcaAdi: yup.string().required("Parça Adı Alanı Boş Geçilemez."),
  ParcaTuru: yup.string().required("Parça Türü Alanı Boş Geçilemez."),
});

export const addCihazTipiSchema = yup.object({
  CihazTipAdi: yup.string().required("Cihaz Tipi Adı Alanı Boş Geçilemez."),
});

export const addIslemDurumSchema = yup.object({
  IslemDurumAdi: yup.string().required("İşlem Durum Alanı Boş Geçilemez."),
  IslemDurumAciklama: yup
    .string()
    .required("İşlem Durum Açıklama Alanı Boş Geçilemez."),
});

export const addCihazSchema = yup.object({
  //CihazAdi: yup.string().required("Cihaz Adı Alanı Boş Geçilemez."),
  SeriNo: yup.string().required("Seri No Alanı Boş Geçilemez."),
  //UretimYili: yup.string(),
  Barkod: yup.string(),
  FaturaNo: yup.string(),
  //GarantiYili: yup.string(),
  Aciklama: yup.string(),
});

export const addServisCihazSchema = yup.object({
  YapilanIslem: yup.string(),
  Aciklama: yup.string(),
  MusteriAciklamasi: yup
    .string()
    .required("Müşteri Açıklama Alanı Boş Geçilemez."),
});

export const addUserServisCihazSchema = yup.object({
  MusteriAciklamasi: yup
    .string()
    .required("Müşteri Açıklama Alanı Boş Geçilemez."),
});

export const addKullaniciSchema = yup.object({
  AdSoyad: yup.string().required("Ad Alanı Boş Geçilemez."),
  //Soyad: yup.string().required("Soyad Alanı Boş Geçilemez."),
  Email: yup.string(),
  //.email("Geçerli Bir E-posta adresi giriniz!")
  //.required("Mail Alanı Boş Geçilemez!"),
  Sifre: yup.string().required("Şifre Alanı Boş Geçilemez!"),
  SifreTekrar: yup.string().required("Şifre Tekrarı Alanı Boş Geçilemez!"),
});

export const forgetPasswordSchema = yup.object({
  email: yup
    .string()
    .required("Email Alanı Boş Geçilemez")
    .email("Lütfen Geçerli Bir Mail Formatı Girin."),
});
