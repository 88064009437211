import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getIlceler, getSehirler } from "services/api/yonetimApi/api";
import { SehirSelect, IlceSelect, CariTipSelect } from "../generalComponents";
import { musteriTipData } from "data/data";
import { addCari } from "services/api/cariApi/api";
import { useFormik } from "formik";
import {
  addCariSchema,
  addCariSchemaTc,
  addCariSchemaVergiNo,
} from "services/helpers/validations";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PopupAddCari({ onClose }) {
  const [sehirlerList, setSehirlerList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedMusteriTip, setSelectedMusteriTip] = useState("Musteri");
  const [selectedSehirId, setSelectedSehirId] = useState(20);
  const [selectedIlceId, setSelectedIlceId] = useState(261);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedLoginDurum, setSelectedLoginDurum] = useState(true);
  const [formData, setFormData] = useState({
    CariUnvan: "",
    Ad: "",
    Soyad: "",
    VergiDairesi: "",
    VergiNo: "",
    Tc: "",
    YetkiliAd: "",
    Telefon: "",
    YetkiliGsm: "",
    Email: "",
    Adres: "",
    selectedDurum: "Özel",
    selectedSehirId: "",
  });

  useEffect(() => {
    getSehirlerProcess();
    getIlceProcess(selectedSehirId);
  }, []);

  // const getValidationSchema = (selectedTuzelDurum) => {
  //   if (selectedTuzelDurum === "Özel") {
  //     return addCariSchemaTc;
  //   } else {
  //     return addCariSchemaVergiNo;
  //   }
  // };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        CariUnvan: "",
        YetkiliAdSoyadBir: "",
        YetkiliAdSoyadIki: "",
        VergiDairesi: "",
        TcOrVergiNo: "",
        YetkiliTelefonBir: "",
        YetkiliTelefonIki: "",
        YetkiliEmailBir: "",
        YetkiliEmailIki: "",
        CariTelefon: "",
        Adres: "",
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const CariUnvanSubmit = values.CariUnvan;
        const YetkiliAdSoyadBirSubmit = values.YetkiliAdSoyadBir;
        const YetkiliAdSoyadIkiSubmit = values.YetkiliAdSoyadIki;
        const VergiDairesiSubmit = values.VergiDairesi;
        const TcOrVergiNoSubmit = values.TcOrVergiNo;
        const YetkiliTelefonBirSubmit = values.YetkiliTelefonBir;
        const YetkiliTelefonIkiSubmit = values.YetkiliTelefonIki;
        const YetkiliEmailBirSubmit = values.YetkiliEmailBir;
        const YetkiliEmailIkiSubmit = values.YetkiliEmailIki;
        const CariTelefon = values.CariTelefon;
        const AdresSubmit = values.Adres;

        addCariProcess(
          CariUnvanSubmit,
          YetkiliAdSoyadBirSubmit,
          YetkiliAdSoyadIkiSubmit,
          VergiDairesiSubmit,
          //VergiNoSubmit,
          TcOrVergiNoSubmit,
          YetkiliTelefonBirSubmit,
          YetkiliTelefonIkiSubmit,
          YetkiliEmailBirSubmit,
          YetkiliEmailIkiSubmit,
          AdresSubmit,
          CariTelefon
        );
      },
      validationSchema: addCariSchemaTc,
    });

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      if (selectedSehirId && selectedIlceId && selectedMusteriTip) {
        setIsFormSubmitted(true);
      }
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleSehirChange = (sehir) => {
    console.log(sehir);
    setSelectedSehirId(sehir.value);

    getIlceProcess(sehir.value);
  };

  const handleIlceChange = (ilce) => {
    console.log(ilce);
    setSelectedIlceId(ilce.value);
  };

  const handleMusteriTipChange = (musteri) => {
    setSelectedMusteriTip(musteri.value);
  };

  const getIlceProcess = async (sehirId) => {
    try {
      const getIlcelerApi = await getIlceler(sehirId);
      //console.log(getIlcelerApi.data);
      setIlcelerList(getIlcelerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getIlcelerProcess -> Error: ", error);
    }
  };

  const getSehirlerProcess = async () => {
    try {
      const getSehirlerApi = await getSehirler();
      //console.log(getSehirlerApi.data);
      setSehirlerList(getSehirlerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getSehirlerProcess -> Error: ", error);
    }
  };

  const addCariProcess = async (
    CariUnvanSubmit,
    YetkiliAdSoyadBirSubmit,
    YetkiliAdSoyadIkiSubmit,
    VergiDairesiSubmit,
    //VergiNoSubmit,
    TcOrVergiNoSubmit,
    YetkiliTelefonBirSubmit,
    YetkiliTelefonIkiSubmit,
    YetkiliEmailBirSubmit,
    YetkiliEmailIkiSubmit,
    AdresSubmit,
    CariTelefon
  ) => {
    console.log(
      CariUnvanSubmit,
      YetkiliAdSoyadBirSubmit,
      YetkiliAdSoyadIkiSubmit,
      YetkiliEmailBirSubmit,
      YetkiliEmailIkiSubmit,
      YetkiliTelefonBirSubmit,
      YetkiliTelefonIkiSubmit,
      selectedLoginDurum,
      CariTelefon,
      AdresSubmit
    );
    try {
      const addCariApi = await addCari(
        CariUnvanSubmit,
        YetkiliAdSoyadBirSubmit,
        YetkiliAdSoyadIkiSubmit,
        YetkiliTelefonBirSubmit,
        YetkiliTelefonIkiSubmit,
        YetkiliEmailBirSubmit,
        YetkiliEmailIkiSubmit,
        CariTelefon,
        //formData.selectedDurum,
        selectedMusteriTip,
        VergiDairesiSubmit,
        //VergiNoSubmit,
        TcOrVergiNoSubmit,
        selectedSehirId,
        selectedIlceId,
        AdresSubmit,
        selectedLoginDurum
      );
      if (addCariApi.success) {
        console.log(addCariApi.basicAciklama);
        //toast.success(addCariApi.basicAciklama);
        toast.success(addCariApi.basicAciklama);
        onClose();
      } else {
        //console.log("Cari Eklenemedi");
        toast.error(addCariApi.basicAciklama);
      }
    } catch (error) {
      console.log("PopupAddCari -> addCariProcess -> Error: ", error);
    }
  };

  // const handleDurumChange = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     selectedDurum: value,
  //     // Seçilen duruma göre VergiNo veya Tc değerini sıfırlayalım
  //     VergiNo: value === "Özel" ? "" : formData.VergiNo,
  //     Tc: value === "Özel" ? formData.Tc : "",
  //   }));
  // };

  const handleLoginDurumChange = (e) => {
    const { value } = e.target;
    setSelectedLoginDurum(value == "true");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Yeni Cari Ekle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          {/* <div className="md:col-span-2 col-span-1 flex md:flex-row flex-col items-center">
            <label className="block text-sm font-semibold mr-4">Durum</label>
            <div className="flex items-center">
              <input
                type="radio"
                id="ozel"
                name="selectedDurum"
                value="Özel"
                checked={formData.selectedDurum === "Özel"}
                onChange={handleDurumChange}
                className="mr-2"
              />
              <label htmlFor="ozel" className="mr-4">
                Özel
              </label>
              <input
                type="radio"
                id="tuzel"
                name="selectedDurum"
                value="Tüzel"
                checked={formData.selectedDurum === "Tüzel"}
                onChange={handleDurumChange}
                className="mr-2"
              />
              <label htmlFor="tuzel" className="mr-4">
                Tüzel
              </label>
              <input
                type="radio"
                id="kamu"
                name="selectedDurum"
                value="Kamu"
                checked={formData.selectedDurum === "Kamu"}
                onChange={handleDurumChange}
                className="mr-2"
              />
              <label htmlFor="kamu">Kamu</label>
            </div>
          </div> */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="CariUnvan"
            >
              Cari Ünvanı
            </label>
            <input
              type="text"
              id="CariUnvan"
              name="CariUnvan"
              value={values.CariUnvan.toUpperCase()}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.CariUnvan && touched.CariUnvan && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.CariUnvan}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiDairesi"
            >
              Vergi Dairesi
            </label>
            <input
              type="text"
              id="VergiDairesi"
              name="VergiDairesi"
              value={values.VergiDairesi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiDairesi && touched.VergiDairesi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiDairesi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="YetkiliAdSoyad"
            >
              Yetkili Ad - Soyad(1)
            </label>
            <input
              type="text"
              id="YetkiliAdSoyadBir"
              name="YetkiliAdSoyadBir"
              value={values.YetkiliAdSoyadBir}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAdSoyadBir && touched.YetkiliAdSoyadBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAdSoyadBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="yetkiliAdSoyadIki"
            >
              Yetkili Ad - Soyad(2)
            </label>
            <input
              type="text"
              id="YetkiliAdSoyadIki"
              name="YetkiliAdSoyadIki"
              value={values.YetkiliAdSoyadIki}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAdSoyadIki && touched.YetkiliAdSoyadIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAdSoyadIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Yetkili Telefon(1)
            </label>
            <input
              type="text"
              id="YetkiliTelefonBir"
              name="YetkiliTelefonBir"
              value={values.YetkiliTelefonBir}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliTelefonBir && touched.YetkiliTelefonBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliTelefonBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Yetkili Telefon(2)
            </label>
            <input
              type="text"
              id="YetkiliTelefonIki"
              name="YetkiliTelefonIki"
              value={values.YetkiliTelefonIki}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliTelefonIki && touched.YetkiliTelefonIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliTelefonIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              Yetkili E-mail(1)
            </label>
            <input
              type="text"
              id="YetkiliEmailBir"
              name="YetkiliEmailBir"
              value={values.YetkiliEmailBir}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliEmailBir && touched.YetkiliEmailBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliEmailBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              Yetkili E-mail(2)
            </label>
            <input
              type="text"
              id="YetkiliEmailIki"
              name="YetkiliEmailIki"
              value={values.YetkiliEmailIki}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliEmailIki && touched.YetkiliEmailIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliEmailIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Cari Telefon
            </label>
            <input
              type="text"
              id="CariTelefon"
              name="CariTelefon"
              value={values.CariTelefon}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.CariTelefon && touched.CariTelefon && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.CariTelefon}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="TcOrVergiNo"
            >
              TC / Vergi No
            </label>
            <input
              type="text"
              id="TcOrVergiNo"
              name="TcOrVergiNo"
              value={values.TcOrVergiNo}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.TcOrVergiNo && touched.TcOrVergiNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.TcOrVergiNo}
                </p>
              </div>
            )}
          </div>

          {/* {formData.selectedDurum === "Özel" ? (
            <>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold mb-1"
                  htmlFor="Tc"
                >
                  TC Kimlik No
                </label>
                <input
                  type="text"
                  id="Tc"
                  name="Tc"
                  value={values.Tc}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-200 p-1 rounded-md"
                />
                {errors.Tc && touched.Tc && (
                  <div>
                    <p className="font-semibold text-xs text-red-color">
                      {errors.Tc}
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold mb-1"
                  htmlFor="VergiNo"
                >
                  Vergi No
                </label>
                <input
                  type="text"
                  id="VergiNo"
                  name="VergiNo"
                  value={values.VergiNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border-2 border-gray-200 p-1 rounded-md"
                />
                {errors.VergiNo && touched.VergiNo && (
                  <div>
                    <p className="font-semibold text-xs text-red-color">
                      {errors.VergiNo}
                    </p>
                  </div>
                )}
              </div>
            </>
          )} */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="MusteriTip"
            >
              Müşteri Tür
            </label>
            <CariTipSelect
              options={musteriTipData}
              onChange={handleMusteriTipChange}
              defaultValue={selectedMusteriTip}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
              Şehir
            </label>
            <SehirSelect
              options={sehirlerList}
              onChange={handleSehirChange}
              addOperation={true}
              defaultValue={selectedSehirId}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
              İlçe
            </label>
            <IlceSelect
              options={ilcelerList}
              onChange={handleIlceChange}
              addOperation={true}
              defaultValue={selectedIlceId}
            />
          </div>

          <div className="mb-4">
            <div className="flex flex-col justify-center items-start">
              <label className="block text-sm font-semibold mr-4">
                Kullanıcı Oluşturulsun mu ?
              </label>
              <div className=" flex flex-row mt-3 ml-3">
                <input
                  type="radio"
                  id="evet"
                  name="selectedLoginDurum"
                  value="true"
                  checked={selectedLoginDurum === true}
                  onChange={handleLoginDurumChange}
                  className="mr-2"
                />
                <label htmlFor="evet" className="mr-4">
                  Evet
                </label>

                <input
                  type="radio"
                  id="hayir"
                  name="selectedLoginDurum"
                  value="false"
                  checked={selectedLoginDurum === false}
                  onChange={handleLoginDurumChange}
                  className="mr-2"
                />
                <label htmlFor="hayir" className="mr-4">
                  Hayır
                </label>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Adres">
              Adres
            </label>
            <textarea
              type="text"
              id="Adres"
              name="Adres"
              value={values.Adres}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              rows={3}
            />
            {errors.Adres && touched.Adres && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Adres}
                </p>
              </div>
            )}
          </div>

          <div className="md:col-span-2 col-span-1">
            <button
              type="submit"
              className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PopupAddCari;
