import React, { useEffect, useState } from "react";
import { getSube } from "services/api/cariApi/api";
import { getIlceler, getModeller } from "services/api/yonetimApi/api";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function ModelSelect({
  options,
  onChange,
  defaultValue,
  addOperation,
  selectedMarkaId,
  filterOperation = true,
  onClickButton,
  disabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [modellerList, setModellerList] = useState([]);

  useEffect(() => {
    if (selectedMarkaId) {
      setSelectedOption(null);
      getModellerProcess(selectedMarkaId).then((modeller) => {
        setModellerList(modeller);
      });
    } else {
      //console.log("Add Yeri");
      setSelectedOption(null);
      setModellerList(options);
    }
  }, [selectedMarkaId, defaultValue, options]);

  useEffect(() => {
    if (defaultValue && modellerList.length > 0) {
      const defaultModel = modellerList.find(
        (model) => model.ModelId === defaultValue
      );
      if (defaultModel != undefined) {
        setSelectedOption({
          value: defaultModel.ModelId,
          label: defaultModel.ModelAdi,
        });
      }
    }
  }, [defaultValue, modellerList]);

  const transformToOption = (data) => {
    return data.map((item) => ({
      value: item.ModelId,
      label: item.ModelAdi,
    }));
  };

  const modelOptions = transformToOption(options);
  const modellerListOptions = transformToOption(modellerList);

  const getModellerProcess = async (markaId) => {
    try {
      const getModellerApi = await getModeller(markaId);
      return getModellerApi.data.data;
    } catch (error) {
      console.log("ModelSelect -> getModellerProcess -> Error: ", error);
    }
  };

  const handleModelChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleModelChange}
        options={addOperation ? modelOptions : modellerListOptions}
        placeholder={addOperation ? "Model..." : "Model..."}
        value={selectedOption}
        isDisabled={disabled}
      />
      {!filterOperation && (
        <IconButton
          Icon={RiAddLine}
          bgColor={"primary-brand-color"}
          iconColor={"white-color"}
          onPress={onClickButton}
        />
      )}
    </div>
  );
}

export default ModelSelect;
