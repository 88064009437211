import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ConfirmModal } from "./ui/generalComponents";

const AdminPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // Menü başlangıçta kapalı olarak ayarlandı
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal durumu için state
  const location = useLocation();
  const currentUrl = location.pathname;
  const Menus = [
    { title: "Servis", src: "ServisIcon", path: "/admin/servisler" },
    { title: "Cari", src: "CariIcon", path: "/admin/cari" },
    { title: "Cihazlar", src: "CihazIcon", path: "/admin/cihazlar" },
    { title: "Yönetim", src: "YonetimIcon", path: "/admin/yonetim" },
    {
      title: "Kullanıcılar",
      src: "KullanicilarIcon",
      path: "/admin/kullanicilar",
    },
  ];

  const MenusTeknisyen = [
    { title: "Servis", src: "ServisIcon", path: "/admin/servisler" },
    { title: "Cari", src: "CariIcon", path: "/admin/cari" },
    { title: "Cihazlar", src: "CihazIcon", path: "/admin/cihazlar" },
    { title: "Yönetim", src: "YonetimIcon", path: "/admin/yonetim" },
    // { title: "Kullanıcılar", src: "Folder", path: "/admin/kullanicilar" },
  ];

  useEffect(() => {
    //console.log(currentUrl);
    console.log(localStorage.getItem("role"));
  }, [currentUrl]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalButton = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="flex w-full h-full">
      <div
        className={`${
          open ? "w-72" : "w-20"
        } bg-primary-brand-color h-auto p-5 pt-8 relative duration-300`}
      >
        <img
          src="../assets/test/control.png"
          alt="controlImage"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src={`../assets/test/${open ? "fillOverLogo.png" : "FabIcon.png"}`}
            alt="logoImage"
            className={`w-18 h-12 cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
        </div>
        {localStorage.getItem("role") === "Teknisyen" ? (
          <ul className="pt-2">
            {MenusTeknisyen.map((Menu, index) => (
              <Link to={Menu.path} key={index}>
                <li
                  className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 ${
                    Menu.gap ? "mt-9" : "mt-2"
                  } ${currentUrl === Menu.path ? "bg-light-white" : ""}`}
                >
                  <img
                    src={`../assets/test/${Menu.src}.png`}
                    alt="menuImage"
                    className="w-6 h-6"
                  />
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {Menu.title}
                  </span>
                </li>
              </Link>
            ))}
            <li
              className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 mt-2"
              onClick={handleModalOpen}
            >
              <img
                src="../assets/test/Setting.png"
                alt="settingsImage"
                className="w-6 h-6"
              />
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                Çıkış Yap
              </span>
            </li>
          </ul>
        ) : (
          <ul className="pt-2">
            {Menus.map((Menu, index) => (
              <Link to={Menu.path} key={index}>
                <li
                  className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 ${
                    Menu.gap ? "mt-9" : "mt-2"
                  } ${currentUrl === Menu.path ? "bg-light-white" : ""}`}
                >
                  <img
                    src={`../assets/test/${Menu.src}.png`}
                    alt="menuImage"
                    className="w-6 h-6"
                  />
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {Menu.title}
                  </span>
                </li>
              </Link>
            ))}
            <li
              className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 mt-2"
              onClick={handleModalOpen}
            >
              <img
                src="../assets/test/ExitIcon.png"
                alt="settingsImage"
                className="w-6 h-6"
              />
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                Çıkış Yap
              </span>
            </li>
          </ul>
        )}
      </div>
      <div className="h-screen flex-1 p-2">
        <Outlet />
      </div>
      {isModalOpen && (
        <ConfirmModal
          message={"Çıkış Yapmak İstediğinizden Emin misiniz?"}
          onCancel={handleModalClose}
          onConfirm={handleModalButton}
          possButtonText="Çıkış Yap"
        />
      )}
    </div>
  );
};

export default AdminPage;
