import React from "react";

function ConfirmModal({
  message,
  onConfirm,
  onCancel,
  possButtonText = "Pasife Al",
}) {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-4 rounded-lg w-11/12 max-w-md h-auto border-2 border-primary-brand-color overflow-hidden">
        <p className="text-lg md:text-xl font-semibold mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="bg-primary-brand-color text-white-color px-4 py-2 rounded-md mr-2 md:mr-4 mb-2 md:mb-0 w-full md:w-auto"
          >
            İptal
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-color text-white-color px-4 py-2 rounded-md mr-2 md:mr-4 mb-2 md:mb-0 w-full md:w-auto"
          >
            {possButtonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
