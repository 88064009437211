import axios from "axios";
import { ADD_CIHAZ, GET_CIHAZ, UPDATE_CIHAZ } from "services/helpers/contants";
import axiosApiInstance from "../axiosClient";

export const addCihaz = async (
  cariId,
  //cihazAdi,
  markaId,
  modelId,
  seriNo,
  //uretimYili,
  barkod,
  subeId,
  cihazTipId,
  satisTarihi,
  faturaNo,
  garantiBaslangicTarihi,
  //garantiYili,
  aciklama
) => {
  console.log(garantiBaslangicTarihi);
  try {
    const { data } = await axiosApiInstance.post(`${ADD_CIHAZ}`, {
      CariId: cariId,
      //CihazAdi: cihazAdi,
      MarkaId: markaId,
      ModelId: modelId,
      SeriNo: seriNo,
      //UretimYili: uretimYili,
      Barkod: barkod,
      SubeId: subeId,
      CihazTipId: cihazTipId,
      SatisTarihi: satisTarihi,
      FaturaNo: faturaNo,
      GarantiBaslangici: garantiBaslangicTarihi,
      //GarantiYili: garantiYili,
      Aciklama: aciklama,
    });

    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log("cariApi -> api -> addCari -> Cari Eklenirken Hata");
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("CihazApi -> api -> addCihaz -> Error: ", err);
  }
};

export const getCihaz = async (
  Durum,
  PageNumber,
  PageSize,
  Search,
  CariId,
  SubeId,
  MarkaId,
  ModelId,
  CihazTipId
) => {
  try {
    const { data } = await axiosApiInstance.post(`${GET_CIHAZ}`, {
      Durum: Durum,
      PageNumber: PageNumber,
      PageSize: PageSize,
      Search: Search,
      CariId: CariId,
      SubeId: SubeId,
      MarkaId: MarkaId,
      ModelId: ModelId,
      CihazTipId: CihazTipId,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "cihazApi -> api -> getCıhaz -> Veri Çekilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("CihazApi -> api -> getCihaz -> Error: ", err);
  }
};

export const updateCihaz = async (
  isDurum,
  firmaCihazId,
  cariId,
  cihazAdi,
  markaId,
  modelId,
  seriNo,
  //uretimYili,
  barkod,
  subeId,
  cihazTipId,
  satisTarihi,
  faturaNo,
  garantiBaslangici,
  //garantiYili,
  aciklama
) => {
  try {
    const { data } = await axiosApiInstance.post(`${UPDATE_CIHAZ}`, {
      Durum: isDurum,
      FirmaCihazId: firmaCihazId,
      CariId: cariId,
      CihazAdi: cihazAdi,
      MarkaId: markaId,
      ModelId: modelId,
      SeriNo: seriNo,
      //UretimYili: uretimYili,
      Barkod: barkod,
      SubeId: subeId,
      CihazTipId: cihazTipId,
      SatisTarihi: satisTarihi,
      FaturaNo: faturaNo,
      GarantiBaslangici: garantiBaslangici,
      //GarantiYili: garantiYili,
      Aciklama: aciklama,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "cihazApi -> api -> updateCihaz -> Cihaz Güncellenirken Hata"
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("CihazApi -> api -> updateCihaz -> Error: ", err);
  }
};
