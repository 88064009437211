import axios from "axios";
import { refreshTokenUser } from "./authApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Axios istemcisi oluşturma
const axiosApiInstance = axios.create();

// İstek kesici (Request interceptor)
axiosApiInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("userToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Yanıt kesici (Response interceptor)
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    try {
      if (error.response.status === 403) {
        console.log("403 yedin kardeş");
        toast.error("Bu İşlemi Gerçekleştirmek İçin Yetkiye Sahip Değilsiniz");
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken == null || refreshToken == undefined) {
          throw new Error("Refresh Token not Found");
        }
        const response = await refreshTokenUser(refreshToken);
        console.log(response);
        const newAccessToken = response.token;

        localStorage.setItem("userToken", newAccessToken);

        axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

        return axiosApiInstance(originalRequest);
      }
    } catch (err) {
      window.location.href = "/";

      return Promise.reject(err);
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
