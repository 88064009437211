//export const API_URL = "https://stapi.biayazilim.com";
export const API_URL = "https://api.fillover.com";
//export const API_URL = "http://192.168.1.100:5036";
//export const API_URL = "http://192.168.1.117:5036";
//export const API_URL = "http://192.168.1.133:5036";
//export const API_URL = "https://bilarapi.biayazilim.com";

// Cari Api
export const GET_CARI = `${API_URL}/api/Cari/GetCariler`;
export const ADD_CARI = `${API_URL}/api/Cari/AddCari`;
export const AKTIF_CARI = `${API_URL}/api/Cari/ActivateCari`;
export const DELETE_CARI = `${API_URL}/api/Cari/DeleteCari`;
export const UPDATE_CARI = `${API_URL}/api/Cari/UpdateCari`;
export const GET_CARI_SUBE = `${API_URL}/api/Cari/GetCariSubeler`;
export const ADD_CARI_SUBE = `${API_URL}/api/Cari/AddSube`;
export const DELETE_SUBE = `${API_URL}/api/Cari/DeleteSube`;
export const UPDATE_SUBE = `${API_URL}/api/Cari/UpdateSube`;
export const GET_CARI_BY_ID = `${API_URL}/api/Cari/GetCariDetay`;

// Yonetim Api
export const GET_SEHIRLER = `${API_URL}/api/Yonetim/GetSehirler`;
export const GET_ILCELER = `${API_URL}/api/Yonetim/GetIlceler`;
export const ADD_MARKA = `${API_URL}/api/Yonetim/AddMarka`;
export const GET_MARKALAR = `${API_URL}/api/Yonetim/GetMarkalar`;
export const UPDATE_MARKA = `${API_URL}/api/Yonetim/UpdateMarka`;
export const DELETE_MARKA = `${API_URL}/api/Yonetim/DeleteMarka`;
export const GET_MODELLER = `${API_URL}/api/Yonetim/GetModels`;
export const ADD_MODEL = `${API_URL}/api/Yonetim/AddModel`;
export const DELETE_MODEL = `${API_URL}/api/Yonetim/DeleteModel`;
export const UPDATE_MODEL = `${API_URL}/api/Yonetim/UpdateModel`;
export const ADD_PARCA = `${API_URL}/api/Yonetim/AddParca`;
export const GET_PARCALAR = `${API_URL}/api/Yonetim/GetParcalar`;
export const UPDATE_PARCA = `${API_URL}/api/Yonetim/UpdateParca`;
export const ADD_CIHAZ_TIP = `${API_URL}/api/Yonetim/AddCihazTip`;
export const GET_CIHAZ_TIPLERI = `${API_URL}/api/Yonetim/GetCihazTipleri`;
export const UPDATE_CIHAZ_TIP = `${API_URL}/api/Yonetim/UpdateCihazTip`;
export const ADD_ISLEM_DURUM = `${API_URL}/api/Yonetim/AddIslemDurum`;
export const GET_ISLEM_DURUM = `${API_URL}/api/Yonetim/GetIslemDurumlari`;
export const UPDATE_ISLEM_DURUM = `${API_URL}/api/Yonetim/UpdateIslemDurum`;

// Cihaz Api
export const ADD_CIHAZ = `${API_URL}/api/Cihaz/AddCihaz`;
export const GET_CIHAZ = `${API_URL}/api/Cihaz/GetCihazList`;
export const UPDATE_CIHAZ = `${API_URL}/api/Cihaz/UpdateCihaz`;

// Kullanici Api
export const GET_ALL_KULLANİCİ = `${API_URL}/api/Kullanici/GetAllKullanici`;
export const ADD_KULLANİCİ = `${API_URL}/api/Kullanici/AddKullanici`;
export const GET_KULLANİCİ_YETKI_BY_ID = `${API_URL}/api/Kullanici/GetKullaniciYetkilerById`;
export const UPDATE_KULLANİCİ = `${API_URL}/api/Kullanici/UpdateKullanici`;

// Servis Api
export const ADD_SERVIS = `${API_URL}/api/Servis/AddServis`;
export const GET_SERVISLER = `${API_URL}/api/Servis/GetServisler`;
export const GET_SERVISLER_BY_ID = `${API_URL}/api/Servis/GetServisById`;
export const UPDATE_SERVIS = `${API_URL}/api/Servis/UpdateServis`;
export const DELETE_SERVIS = `${API_URL}/api/Servis/DeleteServis`;
export const DELETE_SERVIS_CIHAZ = `${API_URL}/api/Servis/DeleteServisCihaz`;
export const UPDATE_CIHAZ_ISLEM = `${API_URL}/api/Servis/UpdateServisCihazIslem`;
export const ACTIVE_SERVIS_CIHAZ = `${API_URL}/api/Servis/ActivateServisCihaz`;
export const DELETE_SERVIS_BELGE = `${API_URL}/api/Servis/DeleteServisBelge`;
export const DOWNLOAD_SERVIS_BELGELER = `${API_URL}/api/Servis/DownloadServisBelgeler`;
export const USER_ADD_SERVIS = `${API_URL}/api/Servis/KullaniciAddServis`;

// Auth Api
export const LOGIN = `${API_URL}/api/Auth/Login`;
export const REFRESH_LOGIN = `${API_URL}/api/Auth/RefreshLogin`;
export const LOG_OUT = `${API_URL}/api/Auth/Logout`;
export const REGISTER = `${API_URL}/api/Register`;
export const FORGET_PASSWORD = `${API_URL}/api/Auth/SifremiUnuttum`;
export const CHANGE_PASSWORD = `${API_URL}/api/Auth/SifremiDegistir`;
