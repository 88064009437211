import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

//import RobotoFont from "./../../../fonts/Roboto-Black.ttf";
import CanonLogo from "./../../../assets/CanonLogo.png";
import fillOverLogo from "./../../../assets/fillOverLogo.png";

//Font.register({ family: "Roboto Black", src: RobotoFont });

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

const ServisListItemPDF = ({ servis }) => {
  if (!servis) {
    return null;
  }

  const formattedDate = new Date(servis.Tarih).toLocaleDateString("tr-TR");

  const getStatusDescription = (servisIslemDurum) => {
    switch (servisIslemDurum) {
      case "ServisKabul":
        return "Servis Kabul";
      case "MusteriTalebi":
        return "Müşteri Talep";
      case "ParcaBekliyor":
        return "Parça Bekliyor";
      case "IptalEdildi":
        return "İptal Edildi";
      case "Tamamlandi":
        return "Tamamlandı";
      case "DisServiste":
        return "Dış Serviste";
      case "OnayBekliyor":
        return "Onay Bekliyor";
      case "TeslimEdildi":
        return "Teslim Edildi";
      default:
        return "Belirtilmedi";
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <Image src={fillOverLogo} style={styles.logo} />
            <Text style={styles.titleMain}>Servis Formu</Text>
            <Text style={styles.textDate}>
              <Text>Tarih:</Text> {formattedDate}
            </Text>
          </View>
          <Text style={styles.text}>
            <Text>Müşteri / Şirket Adı:</Text> {servis.Cari.CariUnvan}
          </Text>
          {servis.Sube != null ? (
            <Text style={styles.text}>
              <Text>Müşteri Şube Adresi:</Text> {servis.Sube.Adres}
            </Text>
          ) : (
            <Text style={styles.text}>
              <Text>Müşteri Adresi:</Text> {servis.Cari.Adres}
            </Text>
          )}

          <Text style={styles.text}>
            <Text>İşlem Durumu:</Text> {getStatusDescription(servis.IslemDurum)}
          </Text>
          <Text style={styles.text}>
            <Text>Teknisyen:</Text>
            {servis.Teknisyen
              ? servis.Teknisyen.NameSurname
              : "Teknisyen Atanmadı"}
          </Text>
          {/* <Text style={styles.text}>
            <Text>İşlem Detayı:</Text> {servis.Aciklama || "Detay Yok"}
          </Text> */}
          <View style={styles.bodyMain}>
            {/* <Text style={styles.sectionTitle}>Serviste Bulunan Cihazlar</Text> */}
            {servis.Cihazlar && servis.Cihazlar.length > 0 ? (
              servis.Cihazlar.map((cihaz, index) => (
                <View style={styles.deviceContainer} key={index}>
                  <View style={styles.deviceHeader}>
                    <Text style={styles.deviceTitle}>Cihaz Adı / Seri No:</Text>
                    <Text style={styles.deviceValue}>
                      {cihaz.FirmaCihaz.CihazAdi}
                    </Text>
                  </View>

                  {cihaz.ServisIslemleri && cihaz.ServisIslemleri.length > 0 ? (
                    cihaz.ServisIslemleri.map((islem, islemIndex) => (
                      <View style={styles.deviceRow} key={islemIndex}>
                        <View style={styles.deviceColumn}>
                          <Text style={styles.label}>Müşteri Açıklaması</Text>
                          <Text style={styles.value}>
                            {islem.MusteriAciklamasi != null
                              ? islem.MusteriAciklamasi
                              : "Bilgi Yok"}
                          </Text>
                        </View>

                        {/* <View style={styles.deviceColumn}>
                          <Text style={styles.label}>Değişen Parça</Text>
                          <Text style={styles.value}>
                            {islem.Parca != null
                              ? islem.Parca.ParcaAdi
                              : "İşlem Yapılmadı"}
                          </Text>
                        </View> */}

                        <View style={styles.deviceColumn}>
                          <Text style={styles.label}>Yapılan İşlem</Text>
                          <Text style={styles.value}>
                            {islem.YapilanIslem != null
                              ? islem.YapilanIslem
                              : "İşlem Yapılmadı"}
                          </Text>
                        </View>
                      </View>
                    ))
                  ) : (
                    <Text style={styles.noData}>Servis işlemi yok</Text>
                  )}
                </View>
              ))
            ) : (
              <Text style={styles.noData}>Cihaz bilgisi yok</Text>
            )}
          </View>

          {/* Tablo Eklemesi */}
          <View style={styles.tableContainer}>
            <Text style={styles.tableTitle}>Parça Bilgileri</Text>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeader}>Parça Adı</Text>
              <Text style={styles.tableHeader}>Parça Adeti</Text>
              <Text style={styles.tableHeader}>Parça Birim Fiyatı</Text>
              <Text style={styles.tableHeader}>Parça Toplam Fiyatı</Text>
            </View>
            {[...Array(6)].map((_, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
              </View>
            ))}
          </View>

          {/* Teknisyen Notu */}
          <View style={{ flexDirection: "row" }}>
            <View style={styles.techNoteContainer}>
              <Text style={styles.techNoteTitle}>Teknisyen Notu</Text>
              <View style={styles.techNoteInput}></View>
            </View>
            {/* Faturalama Bilgisi */}
            <View style={styles.billingInfo}>
              <Text style={styles.billingTitle}>Dip Not!</Text>
              <Text style={styles.billingText}>
                Miktari yukarıda yer alan tutarların toplamı olup fiyatlara %20
                KDV dahil değildir.
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={styles.teknisyenImzaContainer}>
              <Text style={styles.techNoteTitle}>Teknisyen Ad / İmza</Text>
              <View style={styles.imzaInput}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 4,
                    marginTop: 4,
                    fontWeight: "bold",
                  }}
                >
                  {servis.Teknisyen
                    ? servis.Teknisyen.NameSurname
                    : "Teknisyen Atanmadı"}
                </Text>
              </View>
            </View>
            <View style={styles.musteriImzaContainer}>
              <Text style={styles.techNoteTitle}>Müşteri Ad / İmza</Text>
              <View style={styles.imzaInput}></View>
            </View>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={styles.notOnemliContainer}>
              <Text style={styles.techNoteTitle}>Önemli Not !</Text>
              <View style={styles.notInput}>
                <Text style={{ fontSize: 8, textAlign: "center" }}>
                  Teslim tarihinden itibaren 40 (Kırk) gün içerisinde geri
                  alınmayan cihazlardan firmamız sorumlu değildir. *Cihazınızın
                  arıza bildirimlerini ve Sarf malzeme siparişlerinizi online
                  servis.canondenizli.com adresinden verebilirsiniz.Çağrı kayıt
                  bilgilerinizle online olarak cihazınızın durumunu takip
                  edebilirsiniz.
                </Text>
              </View>
            </View>
            <View style={styles.notContainer}>
              <Text style={styles.techNoteTitle}>
                Teknik Servis Merkez Ofis
              </Text>
              <View style={styles.notInput}>
                <Text style={{ fontSize: 8, textAlign: "center" }}>
                  BİLAR FOTOKOPİ KIRT. SAN. TİC. LTD. ŞTİ. Hacıkaplanlar Mah.
                  Barış Cad. No:48/A Pamukkale / DENİZLİ Tel: 0 258 212 75 14
                  Fax:0 258 212 74 53 teknik@canondenizli.com
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ServisListItemPDF;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
    backgroundColor: "white",
    fontFamily: "Ubuntu",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "white",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 100,
    height: 50,
  },
  titleMain: {
    fontSize: 20,
    fontWeight: "normal",
    color: "black",
    //fontFamily: "Roboto Black",
  },
  textDate: {
    fontSize: 12,
    color: "black",
    //fontFamily: "Roboto Black",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: "black",
    //fontFamily: "Roboto Black",
  },
  bodyMain: {
    marginTop: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  deviceContainer: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: "#ffffff",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#ddd",
  },
  deviceHeader: {
    marginBottom: 10,
  },
  deviceTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 2,
    color: "#333",
  },
  deviceValue: {
    fontSize: 12,
    fontWeight: "normal",
    color: "#555",
  },
  deviceRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 5,
  },
  deviceColumn: {
    flex: 1,
    marginRight: 10,
    alignItems: "flex-start",
  },
  label: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 2,
    color: "#333",
  },
  value: {
    fontSize: 8,
    fontWeight: "normal",
    color: "#555",
  },
  noData: {
    fontSize: 10,
    fontWeight: "normal",
    color: "#555",
    textAlign: "center",
  },
  techNoteContainer: {
    width: "75%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  teknisyenImzaContainer: {
    width: "50%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notOnemliContainer: {
    width: "65%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notContainer: {
    width: "35%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  musteriImzaContainer: {
    width: "50%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  techNoteTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
  },
  techNoteInput: {
    height: 60,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  imzaInput: {
    height: 50,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notInput: {
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    minHeight: 35,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  billingInfo: {
    width: "25%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  billingTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
  },
  billingText: {
    fontSize: 8,
    color: "#555",
  },
  tableContainer: {
    marginTop: 8,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    color: "#333",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingVertical: 5,
  },
  tableHeader: {
    fontSize: 8,
    fontWeight: "bold",
    color: "#333",
  },
  tableCell: {
    flex: 1,
    fontSize: 12,
    color: "#555",
    borderLeft: 1,
    borderRight: 1,
    borderColor: "black",
  },
});
