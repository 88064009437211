import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { forgetPassword, loginUser } from "services/api/authApi/api";
import ForgetPasswordModal from "./ui/loginComponents/ForgetPasswordModal";

const LoginPage = ({ setRole }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSendEmail = async (email) => {
    console.log(email);
    try {
      const handleSendEmailApi = await forgetPassword(email);
      if (handleSendEmailApi.succes) {
        setIsModalOpen(false);
        console.log(
          "LoginPage -> handleSendeEmail -> Mail Başarılı Şekilde Gönderildi. ",
          handleSendEmailApi.basicAciklama
        );
        toast.success("Mail Başarılı Şekilde Gönderildi.");
      } else {
        console.log(
          "LoginPage -> handleSendeEmail -> Mail Gönderirken Hata: ",
          handleSendEmailApi.basicAciklama
        );
        toast.error(
          "Mail Gönderirken Hata: " + handleSendEmailApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("LoginPage -> handleSendeEmail -> Error: ", error);
      toast.error("Mail Gönderirken Hata: " + error.message);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log("Kullanıcı adı:", username);
    console.log("Şifre:", password);

    try {
      const loginApi = await loginUser(username, password);
      if (loginApi.succes === true) {
        axios.defaults.headers.common.Authorization = `Bearer ${loginApi.token}`;
        localStorage.setItem("userToken", loginApi.token);
        localStorage.setItem("refreshToken", loginApi.refreshToken);
        localStorage.setItem("role", loginApi.role);
        console.log(loginApi.role);
        setRole(loginApi.role);
        if (
          loginApi.role === "SuperAdmin" ||
          loginApi.role === "Teknisyen" ||
          loginApi.role === "Admin" ||
          loginApi.role === "Muhasebe"
        ) {
          navigate("/admin/servisler");
        } else {
          navigate("/user/servislerim");
        }
      } else {
        console.log("Login Olurken Hata: ", loginApi.basicAciklama);
        toast.error("Login Olurken Hata: " + loginApi.basicAciklama);
      }
    } catch (error) {
      console.log("LoginPage -> handleLogin -> Error: ", error);
      toast.error("Login Olurken Hata: " + error);
    }
  };

  return (
    <div className="min-h-screen flex items-start justify-center flex-col overflow-y-hidden">
      <div className="h-full w-full flex flex-row">
        {/* Sol Taraftaki İçerik */}
        <div className="w-full h-screen bg-third-brand-color">
          <div className="flex items-center justify-center h-full">
            <div className="border-4 rounded-xl border-primary-brand-color bg-third-brand-color p-8 mx-4 w-full max-w-md">
              <h3 className=" md:hidden text-center text-xl font-extrabold text-primary-brand-color mb-8">
                Denizli Fillover Servis Ağı
              </h3>
              <h2 className="text-center text-3xl font-extrabold text-secondary-brand-color mb-8">
                Giriş Yap
              </h2>
              <form className="space-y-4" onSubmit={handleLogin}>
                <div>
                  <label htmlFor="username" className="sr-only">
                    Kullanıcı Adı
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="username"
                    required
                    className="block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring focus:ring-primary-brand-color"
                    placeholder="Kullanıcı Adı"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Şifre
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring focus:ring-primary-brand-color"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 px-4 text-sm font-medium rounded-md text-third-brand-color bg-secondary-brand-color focus:outline-none focus:ring focus:ring-primary-brand-color"
                >
                  Giriş Yap
                </button>
                <button
                  type="button"
                  className="w-full py-2 px-4 text-sm font-medium rounded-md text-third-brand-color bg-primary-brand-color focus:outline-none focus:ring focus:ring-primary-brand-color"
                  onClick={handleOpenModal}
                >
                  Şifremi Değiştir
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Sağ Taraftaki Boş Alan */}
        <div className="hidden lg:flex lg:w-2/3 lg:flex-col items-center justify-center bg-primary-brand-color">
          <div className="h-full flex items-center justify-center flex-col w-full">
            <div className="bg-third-brand-color w-full max-w-md rounded-xl p-8">
              <h2 className="text-4xl font-serif font-semibold text-primary-brand-color text-center">
                Denizli Fillover Servis Ağı
              </h2>
              <p className="text-center text-lg">
                Kocabaş Mahallesi Kocatepe Sokak No:4 Honaz / DENİZLİ
                <br /> Tel: 0 544 226 4957 <br />
                info@fillover.com
              </p>
            </div>
          </div>
          <div className="h-full flex items-center justify-center">
            <img
              src="./assets/generals/fillOverMakine2.png"
              alt="Canon Yazıcı"
              className="object-contain h-96 w-full rounded-3xl"
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ForgetPasswordModal
          onClose={handleCloseModal}
          onSend={handleSendEmail}
        />
      )}
    </div>
  );
};

export default LoginPage;
