import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { islemDurumData } from "data/data";
import { AddButton } from "../generalComponents";
import { IconButton } from "../generalComponents";
import { IoClose } from "react-icons/io5";
import { FaCamera } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";

import { getCari, getSube } from "services/api/cariApi/api";

import CariSelect from "./CariSelect";
import SubeSelect from "./SubeSelect";
import IslemDurumSelect from "./IslemDurumSelect";
import { getCihaz } from "services/api/cihazApi/api";

import { getKullanici } from "services/api/kullaniciApi/api";
import KullaniciSelect from "./KullaniciSelect";
import AddCihazPopup from "./AddCihazPopup";
import TabItemCihaz from "./TabItemCihaz";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { tr } from "date-fns/locale/tr";
import { addServis } from "services/api/servisApi/api";
import PopupAddCari from "../cariComponents/PopupAddCari";
import PopupAddSube from "../subelerComponents/PopupAddSube";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
registerLocale("tr", tr);

function AddServisPopup({ onClose }) {
  const [cariList, setCariList] = useState([]);
  const [subeList, setSubeList] = useState([]);
  const [cihazList, setCihazList] = useState([]);
  const [kullaniciList, setKullaniciList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedIslemDurumId, setSelectedIslemDurumId] =
    useState("ServisKabul");
  const [selectedCariId, setSelectedCariId] = useState(null);
  const [selectedCari, setSelectedCari] = useState(null);
  const [selectedSubeId, setSelectedSubeId] = useState(null);
  const [selectedCihazId, setSelectedCihazId] = useState("");
  const [selectedKullaniciId, setSelectedKullaniciId] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showAddCihazPopup, setShowAddCihazPopup] = useState(false);
  const [gelenCihazList, setGelenCihazList] = useState([]);
  const [currentCihazList, setCurrentCihazList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [addCariShowPopup, setAddCariShowPopup] = useState(false);
  const [addSubeShowPopup, setAddSubeShowPopup] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    getCarilerProcess();
    getCihazlarProcess();
    getKullaniciProcess();
    if (selectedCari != null) {
      getSubelerProcess(selectedCariId);
    }
  }, [addCariShowPopup, addSubeShowPopup]);

  const handleCariChange = (cari) => {
    setSelectedCariId(cari.value);
    setSelectedCari(cari);
    //console.log(cari.value);
    getSubelerProcess(cari.value);
  };

  const handleSubeChange = (sube) => {
    setSelectedSubeId(sube.value);
  };

  const handleIslemDurumChange = (islemDurum) => {
    //console.log(islemDurum);
    setSelectedIslemDurumId(islemDurum.value);
  };

  const handleKullaniciChange = (kullanici) => {
    setSelectedKullaniciId(kullanici.value);
  };

  const handleAddCihaz = (gelenCihazList) => {
    console.log("Cihaz Ekleme:", gelenCihazList);
    const currentList = gelenCihazList[0];
    setCurrentCihazList([...currentCihazList, currentList]);
    //setGelenCihazList(currentList);
  };

  const handleAddCari = () => {
    setAddCariShowPopup(true);
  };

  const handleAddSube = () => {
    if (selectedCariId === null || selectedCariId === undefined) {
      toast.error("Cari Seçimi Yapmadan Şube Ekleme İşlemi Yapamazsınız!");
    } else {
      setAddSubeShowPopup(true);
    }
  };

  const handleAddServis = () => {
    if (currentCihazList.length > 0) {
      addServisProcess(
        selectedCariId,
        selectedSubeId,
        selectedDate,
        selectedIslemDurumId,
        selectedKullaniciId,
        currentCihazList
      );
    } else {
      toast.error("Servise Cihaz Eklemeden Servis Kaydı Oluşturulamaz");
    }
  };

  const getSubelerProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId);
      if (getSubelerApi.success) {
        // console.log(
        //   "AddServisPopup -> getSubelerProcess -> Subeler Başarılı Şekilde Çekildi ",
        //   getSubelerApi.basicAciklama
        // );
        setSubeList(getSubelerApi.data);
      } else {
        console.log(
          "AddServisPopup -> getSubelerProcess -> Subeler Cekilirken Hata: ",
          getSubelerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddServisPopup -> getSubelerProcess -> Error:", error);
    }
  };

  const getCarilerProcess = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        null,
        null,
        null
      );
      //console.log(getSehirlerApi.data);
      setCariList(getCarilerApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCarilerProcess -> Error: ", error);
    }
  };

  const getCihazlarProcess = async () => {
    try {
      const getCihazlarApi = await getCihaz();
      //console.log(getCihazlarApi.data);
      setCihazList(getCihazlarApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCihazlarProcess -> Error: ", error);
    }
  };

  const getKullaniciProcess = async () => {
    try {
      const getKullaniciApi = await getKullanici("Teknisyen");
      setKullaniciList(getKullaniciApi.data);
    } catch (error) {
      console.log("AddServisPopup -> getKullaniciProcess -> Error: ", error);
    }
  };

  const addServisProcess = async (
    CariId,
    SubeId,
    Tarih,
    IslemDurumId,
    TeknisyenId,
    CihazlarList
  ) => {
    try {
      const addServisApi = await addServis(
        CariId,
        SubeId,
        Tarih,
        IslemDurumId,
        TeknisyenId,
        CihazlarList,
        selectedFiles
      );
      if (addServisApi.success) {
        console.log(
          "AddServisPopup -> addServisProcess -> Servis başarılı eklendi: ",
          addServisApi.basicAciklama
        );
        toast.success(addServisApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "AddServisPopup -> addServisProcess -> Servis Eklerken Hata: ",
          addServisApi.basicAciklama
        );
        toast.error(addServisApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddServisPopup -> addServisProcess -> Error: ", error);
    }
  };

  const handleCameraButton = async () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    console.log(event.target.files);
    setSelectedFiles(files);
  };

  const handleFileRemove = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAddCihazPopup = () => {
    if (selectedCariId == null || selectedCariId == undefined) {
      console.log("Button Tıklandı");
      toast.error("Cari Seçimi Yapmadan Servise Cihaz Ekleyemezsiniz!");
      //toast.error("Cari Seçimi Yapmadan Servise Cihaz Ekleyemezsiniz!");
    } else {
      setShowAddCihazPopup(true);
    }
  };

  const handleDeleteClick = (indexToDelete) => {
    setCurrentCihazList((prevList) =>
      prevList.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Yeni Servis Ekle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4">
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Cari">
              Cari
            </label>
            <CariSelect
              options={cariList}
              onChange={handleCariChange}
              onClickButton={handleAddCari}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Sube">
              Lokasyon
            </label>
            <SubeSelect
              options={subeList}
              onChange={handleSubeChange}
              onClickButton={handleAddSube}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurum"
            >
              İşlem Durum
            </label>
            <IslemDurumSelect
              options={islemDurumData}
              onChange={handleIslemDurumChange}
              addOperation={true}
              defaultValue={selectedIslemDurumId}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="kullanici"
            >
              Teknisyen
            </label>
            <KullaniciSelect
              options={kullaniciList}
              onChange={handleKullaniciChange}
            />
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiTarihi"
            >
              Tarih
            </label>
            <DatePicker
              className="w-full border-2 border-gray-color p-1 rounded-md"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy" // Tarih formatını ayarla (isteğe bağlı)
              placeholderText="Tarih Seçin"
              locale="tr"
            />
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiTarihi"
            >
              Bu Servis İçin Dosya Yükle
            </label>
            <div className="flex w-full justify-start items-center">
              <IconButton
                onPress={handleCameraButton}
                bgColor="primary-brand-color"
                Icon={FaFileAlt}
                iconColor="white-color"
                textColor="white-color"
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*,.pdf"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </form>
        <hr />
        <AddButton
          onPress={() => {
            handleAddCihazPopup();
          }}
          bgColor={"primary-brand-color"}
          title={"Servise Cihaz Ekle"}
          iconColor="white-color"
          textColor="white-color"
          disabled={currentCihazList.length >= 1 ? true : false}
        />
        {/* Cihaz Ekleme Popup'ı */}
        {showAddCihazPopup && (
          <AddCihazPopup
            onConfirm={handleAddCihaz}
            onCancel={() => setShowAddCihazPopup(false)}
            cariId={selectedCariId}
          />
        )}
        <div className="mt-4">
          {currentCihazList.map((gelenCihaz, index) => (
            <TabItemCihaz
              key={index}
              cihaz={gelenCihaz}
              addOperation={true}
              onDeleteClick={() => handleDeleteClick(index)}
            />
          ))}
        </div>
        <div className="mt-4">
          {selectedFiles.length > 0 && (
            <>
              <h3 className="text-xl font-semibold">Yüklenen Dosyalar</h3>
              <ul>
                {selectedFiles.map((file, index) => (
                  <li className=" justify-start items-center flex" key={index}>
                    {file.name}
                    <button
                      className="ml-4 text-red-color"
                      onClick={() => handleFileRemove(index)}
                    >
                      Sil
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="flex justify-end items-center w-full">
          <AddButton
            onPress={handleAddServis}
            bgColor={"green-color"}
            title={"Servisi Kaydet"}
            iconColor="white-color"
            textColor="white-color"
          />
        </div>
      </div>
      {addCariShowPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddCari onClose={() => setAddCariShowPopup(false)} />
        </div>
      )}
      {addSubeShowPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddSube
            onClose={() => setAddSubeShowPopup(false)}
            currentCari={selectedCari}
            isSection={false}
          />
        </div>
      )}
    </div>
  );
}

export default AddServisPopup;
