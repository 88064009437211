import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaCheck, FaExpandArrowsAlt, FaTimes, FaCamera } from "react-icons/fa";
import { MdOutlineAddchart } from "react-icons/md";
import { MdEmojiPeople } from "react-icons/md";
import { GiClockwork } from "react-icons/gi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkDone, IoClose } from "react-icons/io5";
import { FaLuggageCart } from "react-icons/fa";
import { TbClockCheck } from "react-icons/tb";
import { TbCameraDown } from "react-icons/tb";
import { FaFileAlt } from "react-icons/fa";
import {
  AiTwotonePrinter,
  AiOutlineArrowDown,
  AiFillEdit,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { IconButton, TextWithLabel } from "../generalComponents";
import { TabPanel, Tab } from "../managerComponents";
import { downloadServisBelgeler } from "services/api/servisApi/api";
import ServisListItemPDF from "./ServisListItemPDF";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

const ServisListItem = ({
  servis,
  handleUpdatePopup,
  onPdfClick,
  isUpdate = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cihazlarIsOpen, setCihazlarIsOpen] = useState({});
  const [isOpenIcon, setIsOpenIcon] = useState(AiOutlineArrowDown);
  const date = new Date(servis.Tarih);
  const formattedDate = date.toLocaleDateString("tr-TR");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleCihazlarOpen = (index) => {
    setCihazlarIsOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    console.log("Selected Servis: ", servis);
    //console.log(servis.Cihazlar[0].FirmaCihaz.CihazTip.CihazTipAdi);
    //console.log(servis.IslemDurum);
  }, []);

  const getStatusIconCheck = (servisIslemDurum) => {
    switch (servisIslemDurum) {
      case "ServisKabul":
        return (
          <MdOutlineAddchart className="text-primary-brand-color" size={22} />
        );
      case "MusteriTalebi":
        return <MdEmojiPeople className="text-primary-brand-color" size={22} />;
      case "ParcaBekliyor":
        return <GiClockwork className="text-yellow-color" size={22} />;
      case "IptalEdildi":
        return <IoIosCloseCircleOutline className="text-red-color" size={22} />;
      case "Tamamlandi":
        return <IoCheckmarkDone className="text-green-color" size={22} />;
      case "DisServiste":
        return <FaLuggageCart className="text-primary-brand-color" size={22} />;
      case "OnayBekliyor":
        return <TbClockCheck className="text-yellow-color" size={22} />;
      case "TeslimEdildi":
        return (
          <IoCheckmarkDone className="text-primary-brand-color" size={22} />
        );
      default:
        return (
          <IoIosCloseCircleOutline
            className="text-primary-brand-color"
            size={22}
          />
        );
    }
  };

  const getStatusDescription = (servisIslemDurum) => {
    switch (servisIslemDurum) {
      case "ServisKabul":
        return "Servis Kabul";
      case "MusteriTalebi":
        return "Müşteri Talep";
      case "ParcaBekliyor":
        return "Parça Bekliyor";
      case "IptalEdildi":
        return "İptal Edildi";
      case "Tamamlandi":
        return "Tamamlandı";
      case "DisServiste":
        return "Dış Serviste";
      case "OnayBekliyor":
        return "Onay Bekliyor";
      case "TeslimEdildi":
        return "Teslim Edildi";
      default:
        return "Belirtilmedi";
    }
  };

  const downloadServisBelgeProcess = async (servis) => {
    console.log(servis);
    try {
      const downloadServisBelgeApi = await downloadServisBelgeler(
        servis.ServisId
      );
      if (downloadServisBelgeApi.succes) {
        console.log(
          "ServisListItem -> downloadServisBelgeProcess -> succes: true",
          downloadServisBelgeApi.basicAciklama
        );
      } else {
        console.log(
          "ServisListItem -> downloadServisBelgeProcess -> succes: false",
          downloadServisBelgeApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "ServisListItem -> downloadServisBelgeProcess -> Error: ",
        error
      );
    }
  };

  return (
    <motion.div
      className="border-2 border-gray-300 rounded-md my-4 overflow-hidden flex flex-col h-full"
      initial={{ height: "fit-content" }}
      animate={{ height: isOpen ? "fit-content" : "96px" }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-row justify-start items-center min-h-24 px-2">
        <div className="h-full w-full flex flex-row justify-start items-center ">
          <div className="flex min-w-36  flex-col items-center mr-3">
            <div className=" border-2 border:primary-brand-color p-3 rounded-full">
              {getStatusIconCheck(servis.IslemDurum)}
            </div>
            <span className="mt-2 text-lg font-semibold text-primary-brand-color">
              {getStatusDescription(servis.IslemDurum)}
            </span>
          </div>

          <div className="flex flex-row justify-center items-center w-full">
            <div className=" w-1/5 justify-start flex items-center">
              <TextWithLabel
                label={"Tarih"}
                value={formattedDate}
                param={":"}
              />
            </div>
            <div className="w-1/5 justify-start flex items-center ">
              <TextWithLabel
                label={"Cari"}
                value={servis.Cari.CariUnvan}
                param={":"}
              />
            </div>
            <div className="w-1/5 justify-start flex items-center">
              <TextWithLabel
                label={"Lokasyon"}
                value={
                  servis.Sube && servis.Sube.SubeAdi
                    ? servis.Sube.SubeAdi
                    : "Şube Girilmemiş"
                }
                param={":"}
              />
            </div>
            <div className="w-2/5 justify-start flex items-center">
              <TextWithLabel
                value={servis.Cihazlar[0].FirmaCihaz.CihazAdi}
                label={"Marka/Model/SeriNo:"}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <IconButton
            onPress={() => onPdfClick(servis)}
            Icon={AiTwotonePrinter}
            bgColor={"light-white"}
            iconColor={"primary-brand-color"}
          />

          <IconButton
            onPress={() => downloadServisBelgeProcess(servis)}
            Icon={FaFileAlt}
            bgColor={"light-white"}
            iconColor={"primary-brand-color"}
          />
          {isUpdate && (
            <IconButton
              onPress={() => handleUpdatePopup(servis)}
              Icon={AiFillEdit}
              bgColor={"light-white"}
              iconColor={"primary-brand-color"}
            />
          )}

          <IconButton
            onPress={toggleOpen}
            Icon={isOpen ? AiOutlineArrowUp : AiOutlineArrowDown}
            bgColor={"light-white"}
            iconColor={"primary-brand-color"}
          />
        </div>
      </div>
      <p className="text-xl font-semibold font-serif px-4 ">
        {"Cihazlar Listesi"}
      </p>

      {servis.Cihazlar.map((cihaz, index) => {
        const satisTarihi = new Date(cihaz.FirmaCihaz.SatisTarihi);
        const formattedSatisTarihi = satisTarihi.toLocaleDateString("tr-TR");
        const garantiBaslangici = new Date(cihaz.FirmaCihaz.GarantiBaslangici);
        const formattedGarantiBaslangici =
          garantiBaslangici.toLocaleDateString("tr-TR");

        return (
          <motion.div
            key={index}
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: isOpen ? 1 : 0, scaleY: isOpen ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className="p-2"
          >
            <motion.div
              className="border border-gray-300 rounded-md  overflow-hidden flex flex-col h-full"
              initial={{ height: "fit-content" }}
              animate={{
                height: cihazlarIsOpen[index] ? "fit-content" : "64px",
              }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex flex-row justify-start items-center min-h-16 px-2  ">
                <div className="h-full w-full flex flex-row justify-start items-center ">
                  <div className="bg-primary-brand-color mr-2 p-3 flex justify-center items-center text-white-color rounded-full">
                    {cihaz.Durum ? <FaCheck /> : <IoClose />}
                  </div>
                  <h2 className="text-lg font-semibold">
                    {cihaz.FirmaCihaz.CihazAdi}
                  </h2>
                </div>
                <div className=" flex flex-row">
                  <IconButton
                    onPress={() => toggleCihazlarOpen(index)}
                    Icon={
                      cihazlarIsOpen[index]
                        ? AiOutlineArrowUp
                        : AiOutlineArrowDown
                    }
                    bgColor={"light-white"}
                    iconColor={"primary-brand-color"}
                  />
                </div>
              </div>
              <motion.div
                initial={{ opacity: 0, scaleY: 0 }}
                animate={{
                  opacity: cihazlarIsOpen[index] ? 1 : 0,
                  scaleY: cihazlarIsOpen[index] ? 1 : 0,
                }}
                transition={{ duration: 0.3 }}
                className="p-4"
              >
                <div className="w-full h-full ">
                  <TabPanel>
                    <Tab label="Cihaz Bilgileri">
                      <div className="flex flex-row">
                        <div className="flex flex-col">
                          <TextWithLabel
                            label={"Marka - Model"}
                            value={`${cihaz.FirmaCihaz.Marka.MarkaAdi} - ${cihaz.FirmaCihaz.Model.ModelAdi}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Seri No"}
                            value={`${cihaz.FirmaCihaz.SeriNo}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Cihaz Tip"}
                            value={`${cihaz.FirmaCihaz.CihazTip.CihazTipAdi}`}
                            param={":"}
                          />
                        </div>
                        <div className="flex flex-col ml-4">
                          <TextWithLabel
                            label={"Fatura No:"}
                            value={`${cihaz.FirmaCihaz.FaturaNo}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Üretim Yılı"}
                            value={`${cihaz.FirmaCihaz.UretimYili}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Barkod"}
                            value={`${cihaz.FirmaCihaz.Barkod}`}
                            param={":"}
                          />
                        </div>
                        <div className="flex flex-col ml-4">
                          <TextWithLabel
                            label={"Satış Tarihi"}
                            value={`${formattedSatisTarihi}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Garanti Başlangıcı"}
                            value={`${formattedGarantiBaslangici}`}
                            param={":"}
                          />

                          <TextWithLabel
                            label={"Garanti Yılı"}
                            value={`${cihaz.FirmaCihaz.GarantiYili}`}
                            param={":"}
                          />
                        </div>
                        <div className="flex flex-col ml-4">
                          <TextWithLabel
                            label={"Açıklama"}
                            value={`${cihaz.FirmaCihaz.Aciklama}`}
                            param={":"}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab label="Servis Bilgileri">
                      <div className="flex flex-wrap">
                        {cihaz.ServisIslemleri.map((servisIslemleri, index) => (
                          <div key={index} className="flex flex-col w-1/2 p-2">
                            <div className="flex flex-col mb-4">
                              <label className="text-sm font-semibold mb-1">
                                Yapılan İşlem:
                              </label>
                              <textarea
                                className="border border-gray-300 rounded-md p-2"
                                value={servisIslemleri.YapilanIslem}
                                readOnly
                              />
                            </div>
                            <div className="flex flex-col mb-4">
                              <label className="text-sm font-semibold mb-1">
                                Teknisyen Açıklaması:
                              </label>
                              <textarea
                                className="border border-gray-300 rounded-md p-2"
                                value={servisIslemleri.Aciklama}
                                readOnly
                              />
                            </div>
                            <div className="flex flex-col mb-4">
                              <label className="text-sm font-semibold mb-1">
                                Müşteri Açıklaması:
                              </label>
                              <textarea
                                className="border border-gray-300 rounded-md p-2"
                                value={servisIslemleri.MusteriAciklamasi}
                                readOnly
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </Tab>
                  </TabPanel>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        );
      })}
    </motion.div>
  );
};
export default ServisListItem;
