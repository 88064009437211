import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getSehirler, getIlceler } from "services/api/yonetimApi/api";
import { getCari } from "services/api/cariApi/api";
import { getCihaz } from "services/api/cihazApi/api";
import { getMarkalar, getModeller } from "services/api/yonetimApi/api";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // Türkçe dil dosyasını içe aktarın
import "react-datepicker/dist/react-datepicker.css";
import { SehirSelect, IlceSelect, CariTipSelect } from "../generalComponents";
import {
  aktifDurumData,
  musteriTipData,
  cariStatusData,
  islemDurumData,
} from "data/data";
import DurumSelect from "../generalComponents/DurumSelect";
import StatusSelect from "../generalComponents/StatusSelect";
import CariSelect from "../deviceComponents/CariSelect";
import CihazTipSelect from "../deviceComponents/CihazTipSelect";
import CihazSelect from "./CihazSelect";
import MarkaSelect from "../deviceComponents/MarkaSelect";
import ModelSelect from "../deviceComponents/ModelSelect";
import IslemDurumSelect from "./IslemDurumSelect";

function PopupFilterServis({ onFilter, clearFilter }) {
  const [cariList, setCariList] = useState([]);
  const [cihazList, setCihazList] = useState([]);
  const [markaList, setMarkaList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedCariId, setSelectedCariId] = useState(null);
  const [selectedCihazId, setSelectedCihazId] = useState(null);
  const [selectedMarkaId, setSelectedMarkaId] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [selectedIslemDurum, setSelectedIslemDurum] = useState(null);
  const [selectedDurum, setSelectedDurum] = useState(null);
  const [baslangicTarih, setBaslangicTarih] = useState(null);
  const [bitisTarih, setBitisTarih] = useState(null);

  useEffect(() => {
    getCarilerProcess();
    getMarkalarProcess();
  }, []);

  const getCarilerProcess = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        null,
        null,
        null
      );
      //console.log(getSehirlerApi.data);
      setCariList(getCarilerApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCarilerProcess -> Error: ", error);
    }
  };

  const getCihazlarProcess = async (cariId) => {
    try {
      const getCihazlarApi = await getCihaz(
        true,
        1,
        1000,
        null,
        cariId,
        null,
        null,
        null,
        null
      );
      console.log(getCihazlarApi.data);
      setCihazList(getCihazlarApi.data.data);
    } catch (error) {
      console.log("AddCihazPopup -> getCihazlarProcess -> Error: ", error);
    }
  };

  const getMarkalarProcess = async () => {
    try {
      const getMarkalarApi = await getMarkalar(1, 1000);
      if (getMarkalarApi.succes) {
        console.log(
          "PopupAddCihaz -> getMarkaProcess -> Markalar Başarılı Şekilde Çekildi ",
          getMarkalarApi.basicAciklama
        );
        setMarkaList(getMarkalarApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getMarkaProcess -> Markalar Çekilirken Hata  ",
          getMarkalarApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getMarkaProcess -> Error: ", error);
    }
  };

  const getModelProcess = async (markaId) => {
    try {
      const getModelApi = await getModeller(markaId, 1, 1000);
      if (getModelApi.succes) {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Modeller Başarılı Çekildi",
          getModelApi.basicAciklama
        );
        setModelList(getModelApi.data.data);
      } else {
        console.log(
          "PopupAddCihaz -> getModelProcess -> Model Çekilirken Hata:",
          getModelApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("PopupAddCihaz -> getModelProcess -> Error: ", error);
    }
  };

  const handleCariChange = (cari) => {
    setSelectedCariId(cari.value);
    getCihazlarProcess(cari.value);
  };

  const handleCihazChange = (cihaz) => {
    setSelectedCihazId(cihaz.value);
  };

  const handleMarkaChange = (marka) => {
    setSelectedMarkaId(marka.value);
    getModelProcess(marka.value);
  };

  const handleModelChange = (model) => {
    //console.log(model.value);
    setSelectedModelId(model.value);
  };

  const handleIslemDurumChange = (islemDurum) => {
    setSelectedIslemDurum(islemDurum.value);
  };

  const handleCariDurumChange = (durum) => {
    setSelectedDurum(durum.value);
  };

  const handleBaslangicTarihChange = (date) => {
    setBaslangicTarih(date);
  };

  const handleBitisTarihChange = (date) => {
    setBitisTarih(date);
  };

  const handleFilter = () => {
    onFilter({
      durum: selectedDurum,
      cariId: selectedCariId,
      firmaCihazId: selectedCihazId,
      markaId: selectedMarkaId,
      modelId: selectedModelId,
      islemDurum: selectedIslemDurum,
      baslangicTarih: baslangicTarih,
      bitisTarih: bitisTarih,
    });
  };

  const handleClear = () => {
    clearFilter();
    setSelectedCariId(null);
    setSelectedCihazId(null);
    setSelectedMarkaId(null);
    setSelectedModelId(null);
    setSelectedIslemDurum(null);
    setSelectedDurum(null);
  };

  return (
    <div className="bg-white-color p-2 rounded-lg w-full h-auto border-2 border-primary-brand-color flex flex-wrap items-center justify-between">
      <div className="flex items-center">
        <div className="mb-2 mr-2  w-52">
          <label className="block text-sm font-semibold mb-1" htmlFor="Cari">
            Cari
          </label>
          <CariSelect
            options={cariList}
            onChange={handleCariChange}
            addOperation={true}
            defaultValue={selectedCariId}
          />
        </div>

        <div className="mb-2 mr-2  w-52">
          <label
            className="block text-sm font-semibold mb-1"
            htmlFor="Cari Cihaz"
          >
            Cari Cihaz
          </label>
          <CihazSelect
            options={cihazList}
            onChange={handleCihazChange}
            addOperation={true}
            defaultValue={selectedCihazId}
            filterOperation={true}
          />
        </div>

        <div className="mb-2 mr-2 w-44">
          <label className="block text-sm font-semibold mb-1" htmlFor="Marka">
            Marka
          </label>
          <MarkaSelect
            options={markaList}
            onChange={handleMarkaChange}
            addOperation={false}
            defaultValue={selectedMarkaId}
          />
        </div>

        <div className="mb-2 mr-2 w-44">
          <label className="block text-sm font-semibold mb-1" htmlFor="Model">
            Model
          </label>
          <ModelSelect
            options={modelList}
            onChange={handleModelChange}
            addOperation={true}
            defaultValue={selectedModelId}
          />
        </div>

        <div className="mb-2 mr-2 w-44">
          <label
            className="block text-sm font-semibold mb-1"
            html-for="IslemDurum"
          >
            İşlem Durum
          </label>
          <IslemDurumSelect
            options={islemDurumData}
            onChange={handleIslemDurumChange}
            addOperation={true}
            defaultValue={selectedIslemDurum}
          />
        </div>

        <div className="mb-2 mr-2 w-44">
          <label
            className="block text-sm font-semibold mb-1"
            htmlFor="BaslangicTarih"
          >
            Başlangıç Tarihi
          </label>
          <DatePicker
            className="w-full border-2 border-gray-color p-1 rounded-md"
            selected={baslangicTarih}
            onChange={handleBaslangicTarihChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="Tarih"
            locale="tr"
          />
        </div>

        <div className="mb-2 mr-2 w-44">
          <label
            className="block text-sm font-semibold mb-1"
            htmlFor="BitisTarih"
          >
            Bitiş Tarihi
          </label>
          <DatePicker
            className="w-full border-2 border-gray-color p-1 rounded-md"
            selected={bitisTarih}
            onChange={handleBitisTarihChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="Tarih"
            locale="tr"
          />
        </div>
      </div>

      <div className="flex mt-2 md:mt-0">
        <button
          type="button"
          className="bg-primary-brand-color text-white-color px-4 py-2 rounded-md mr-2"
          onClick={handleFilter}
        >
          Filtrele
        </button>

        <button
          type="button"
          className="bg-red-color text-white-color px-4 py-2 rounded-md"
          onClick={handleClear}
        >
          Temizle
        </button>
      </div>
    </div>
  );
}

export default PopupFilterServis;
