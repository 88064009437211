import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getIlceler, getSehirler } from "services/api/yonetimApi/api";
import {
  SehirSelect,
  IlceSelect,
  CariTipSelect,
  ConfirmModal,
} from "../generalComponents";

import { musteriTipData } from "data/data";
//import ConfirmModal from "./ConfirmModal";
import {
  addCari,
  aktifCari,
  deleteCari,
  getCariById,
  updateCari,
} from "services/api/cariApi/api";
import { useFormik } from "formik";
import {
  addCariSchema,
  addCariSchemaTc,
  addCariSchemaVergiNo,
} from "services/helpers/validations";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PopupUpdateCari({ onClose, currentCari }) {
  const [sehirlerList, setSehirlerList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedMusteriTip, setSelectedMusteriTip] = useState(
    currentCari.CariTipi
  );
  const [currentsCari, setCurrentsCari] = useState(currentCari);
  const [selectedSehirId, setSelectedSehirId] = useState(currentCari.SehirId);
  const [selectedIlceId, setSelectedIlceId] = useState(currentCari.IlceId);
  const [selectedTuzelDurum, setSelectedTuzelDurum] = useState(
    currentCari.CariOzelTuzel
  );
  const [selectedLoginDurum, setSelectedLoginDurum] = useState(
    currentCari.LoginDurum
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formData, setFormData] = useState({
    CariUnvan: "",
    Ad: "",
    Soyad: "",
    VergiDairesi: "",
    VergiNo: "",
    Tc: "",
    YetkiliAd: "",
    Telefon: "",
    YetkiliGsm: "",
    Email: "",
    Adres: "",
    selectedDurum: "Özel",
    selectedSehirId: "",
  });

  useEffect(() => {
    console.log("--------->", currentCari);
    //checkOzelTuzelDurum();
    getSehirlerProcess();
  }, []);

  useEffect(() => {
    console.log("deneme denem");
    getCariByIdProcess();
  }, [showConfirmModal]);

  const handleSilClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmSil = () => {
    // Silme işlemi burada gerçekleştirilecek
    //onClose();
    console.log("Cari silindi");
    deleteCariProcess();
  };

  const handleConfirmAktif = () => {
    // Aktifleştirme İşlemi Burada gerçekleştirilicek
    aktifCariProcess();
    console.log("Cari Aktifleştirildi");
    //onClose();
  };

  const handleCancelSil = () => {
    setShowConfirmModal(false);
  };

  const deleteCariProcess = async () => {
    try {
      const deleteCariApi = await deleteCari(currentCari.CariId);
      console.log(deleteCariApi.basicAciklama);
      if (deleteCariApi.succes) {
        console.log(deleteCariApi.basicAciklama);
        setShowConfirmModal(false);
      } else {
        console.log("API Error:", deleteCariApi.basicAciklama);
      }
    } catch (error) {
      console.log("PopupUpdateCari -> deleteCariProcess -> Error: ", error);
    }
  };

  const aktifCariProcess = async () => {
    try {
      const aktifCariApi = await aktifCari(currentCari.CariId);
      console.log(aktifCariApi.basicAciklama);
      if (aktifCariApi.succes) {
        console.log(aktifCariApi.basicAciklama);
        setShowConfirmModal(false);
      } else {
        console.log("API Error:", aktifCariApi.basicAciklama);
      }
    } catch (error) {
      console.log("PopupUpdateCari -> aktifCariProcess -> Error: ", error);
    }
  };

  // const getValidationSchema = (selectedTuzelDurum) => {
  //   if (selectedTuzelDurum === "Ozel") {
  //     return addCariSchemaTc;
  //   } else {
  //     return addCariSchemaVergiNo;
  //   }
  // };

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        CariUnvan: currentCari.CariUnvan,
        YetkiliAdSoyadBir: currentCari.BirinciYetkiliAdiSoyadi,
        YetkiliAdSoyadIki: currentCari.IkinciYetkiliAdiSoyadi,
        VergiDairesi: currentCari.VergiDairesi,
        TcOrVergiNo: currentCari.VergiNoOrTC,
        YetkiliTelefonBir: currentCari.BirinciYetkiliGsm,
        YetkiliTelefonIki: currentCari.IkinciYetkiliGsm,
        YetkiliEmailBir: currentCari.BirinciYetkiliEmail,
        YetkiliEmailIki: currentCari.IkinciYetkiliEmail,
        CariTelefon: currentCari.Telefon,
        Adres: currentCari.Adres,
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const CariUnvanSubmit = values.CariUnvan;
        const YetkiliAdSoyadBirSubmit = values.YetkiliAdSoyadBir;
        const YetkiliAdSoyadIkiSubmit = values.YetkiliAdSoyadIki;
        const VergiDairesiSubmit = values.VergiDairesi;
        const TcOrVergiNoSubmit = values.TcOrVergiNo;
        const YetkiliTelefonBirSubmit = values.YetkiliTelefonBir;
        const YetkiliTelefonIkiSubmit = values.YetkiliTelefonIki;
        const YetkiliEmailBirSubmit = values.YetkiliEmailBir;
        const YetkiliEmailIkiSubmit = values.YetkiliEmailIki;
        const CariTelefon = values.CariTelefon;
        const AdresSubmit = values.Adres;

        console.log("------->", currentCari);

        updateCariProcess(
          CariUnvanSubmit,
          YetkiliAdSoyadBirSubmit,
          YetkiliAdSoyadIkiSubmit,
          VergiDairesiSubmit,
          TcOrVergiNoSubmit,
          YetkiliTelefonBirSubmit,
          YetkiliTelefonIkiSubmit,
          YetkiliEmailBirSubmit,
          YetkiliEmailIkiSubmit,
          AdresSubmit,
          CariTelefon
        );
      },
      validationSchema: addCariSchemaTc,
    });

  const handleSehirChange = (sehir) => {
    console.log(sehir);
    setSelectedSehirId(sehir.value);
    getIlceProcess(sehir.value);
  };

  const handleIlceChange = (ilce) => {
    //console.log(ilce);
    setSelectedIlceId(ilce.value);
  };

  // const checkOzelTuzelDurum = () => {
  //   if (currentCari.CariOzelTuzel === "Ozel") {
  //     setSelectedTuzelDurum("Özel");
  //   } else if (currentCari.CariOzelTuzel === "Tuzel") {
  //     setSelectedTuzelDurum("Tüzel");
  //   } else {
  //     setSelectedTuzelDurum("Kamu");
  //   }
  // };

  const handleMusteriTipChange = (musteriTip) => {
    console.log(musteriTip);
    setSelectedMusteriTip(musteriTip.value);
  };

  const getIlceProcess = async (sehirId) => {
    try {
      const getIlcelerApi = await getIlceler(sehirId);
      setIlcelerList(getIlcelerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getIlcelerProcess -> Error: ", error);
    }
  };

  const getSehirlerProcess = async () => {
    try {
      const getSehirlerApi = await getSehirler();
      setSehirlerList(getSehirlerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getSehirlerProcess -> Error: ", error);
    }
  };

  const getCariByIdProcess = async () => {
    try {
      const getCariByIdApi = await getCariById(currentCari.CariId);
      console.log("-------->", getCariByIdApi.data.data.Durum);
      setCurrentsCari(getCariByIdApi.data.data);
    } catch (error) {
      console.log("PopupAddCari -> getCariByIdProcess -> Error: ", error);
    }
  };

  const updateCariProcess = async (
    CariUnvanSubmit,
    YetkiliAdSoyadBirSubmit,
    YetkiliAdSoyadIkiSubmit,
    VergiDairesiSubmit,
    TcOrVergiNoSubmit,
    YetkiliTelefonBirSubmit,
    YetkiliTelefonIkiSubmit,
    YetkiliEmailBirSubmit,
    YetkiliEmailIkiSubmit,
    AdresSubmit,
    CariTelefon
  ) => {
    try {
      const updateCariApi = await updateCari(
        currentCari.CariId,
        CariUnvanSubmit,
        YetkiliAdSoyadBirSubmit,
        YetkiliAdSoyadIkiSubmit,
        YetkiliTelefonBirSubmit,
        YetkiliTelefonIkiSubmit,
        YetkiliEmailBirSubmit,
        YetkiliEmailIkiSubmit,
        CariTelefon,
        selectedMusteriTip,
        VergiDairesiSubmit,
        TcOrVergiNoSubmit,
        selectedSehirId,
        selectedIlceId,
        AdresSubmit,
        selectedLoginDurum
      );
      if (updateCariApi.success) {
        console.log("------->Cari Güncellendi");
        toast.success(updateCariApi.basicAciklama);
        onClose();
      } else {
        console.log("Cari Güncellenemedi");
        toast.error(updateCariApi.basicAciklama);
      }
    } catch (error) {
      console.log("PopupAddCari -> updateCariProcess -> Error: ", error);
    }
  };

  // const handleDurumChange = (e) => {
  //   const { value } = e.target;
  //   setSelectedTuzelDurum(value);
  // };

  const handleLoginDurumChange = (e) => {
    const { value } = e.target;
    setSelectedLoginDurum(value == "true");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Cari Detay & Düzenle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Formun otomatik olarak submit olmasını engelle
            handleSubmit(); // Formik'in handleSubmit fonksiyonunu çağır
          }}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          {/* <div className="md:col-span-2 col-span-1 flex md:flex-row flex-col items-center">
            <label className="block text-sm font-semibold mr-4">Durum</label>
            <div className="flex items-center">
              <input
                type="radio"
                id="ozel"
                name="selectedDurum"
                value="Özel"
                checked={selectedTuzelDurum === "Özel"}
                onChange={handleDurumChange}
                className="mr-2 "
                disabled
              />
              <label htmlFor="ozel" className="mr-4">
                Özel
              </label>
              <input
                type="radio"
                id="tuzel"
                name="selectedDurum"
                value="Tüzel"
                checked={selectedTuzelDurum === "Tüzel"}
                onChange={handleDurumChange}
                className="mr-2"
                disabled
              />
              <label htmlFor="tuzel" className="mr-4">
                Tüzel
              </label>
              <input
                type="radio"
                id="kamu"
                name="selectedDurum"
                value="Kamu"
                checked={selectedTuzelDurum === "Kamu"}
                onChange={handleDurumChange}
                className="mr-2"
                disabled
              />
              <label htmlFor="kamu">Kamu</label>
            </div>
          </div> */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="CariUnvan"
            >
              Cari Ünvanı
            </label>
            <input
              type="text"
              id="CariUnvan"
              name="CariUnvan"
              value={values.CariUnvan}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.CariUnvan && touched.CariUnvan && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.CariUnvan}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiDairesi"
            >
              Vergi Dairesi
            </label>
            <input
              type="text"
              id="VergiDairesi"
              name="VergiDairesi"
              value={values.VergiDairesi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiDairesi && touched.VergiDairesi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiDairesi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Ad">
              Yetkili Ad-Soyad(1)
            </label>
            <input
              type="text"
              id="YetkiliAdSoyadBir"
              name="YetkiliAdSoyadBir"
              value={values.YetkiliAdSoyadBir}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAdSoyadBir && touched.YetkiliAdSoyadBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAdSoyadBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Ad">
              Yetkili Ad-Soyad(2)
            </label>
            <input
              type="text"
              id="YetkiliAdSoyadIki"
              name="YetkiliAdSoyadIki"
              value={values.YetkiliAdSoyadIki}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAdSoyadIki && touched.YetkiliAdsoyadIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAdSoyadIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Yetkili Telefon(1)
            </label>
            <input
              type="text"
              id="YetkiliTelefonBir"
              name="YetkiliTelefonBir"
              value={values.YetkiliTelefonBir}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliTelefonBir && touched.YetkiliTelefonBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliTelefonBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Yetkili Telefon(2)
            </label>
            <input
              type="text"
              id="YetkiliTelefonIki"
              name="YetkiliTelefonIki"
              value={values.YetkiliTelefonIki}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliTelefonIki && touched.YetkiliTelefonIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliTelefonIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              Yetkili E-mail(1)
            </label>
            <input
              type="text"
              id="YetkiliEmailBir"
              name="YetkiliEmailBir"
              value={values.YetkiliEmailBir}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliEmailBir && touched.YetkiliEmailBir && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliEmailBir}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              Yetkili E-mail(2)
            </label>
            <input
              type="text"
              id="YetkiliEmailIki"
              name="YetkiliEmailIki"
              value={values.YetkiliEmailIki}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliEmailIki && touched.YetkiliEmailIki && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliEmailIki}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Cari Telefon
            </label>
            <input
              type="text"
              id="CariTelefon"
              name="CariTelefon"
              value={values.CariTelefon}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.CariTelefon && touched.CariTelefon && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.CariTelefon}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="TcOrVergiNo"
            >
              TC / VergiNo
            </label>
            <input
              type="text"
              id="TcOrVergiNo"
              name="TcOrVergiNo"
              value={values.TcOrVergiNo}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.TcOrVergiNo && touched.TcOrVergiNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.TcOrVergiNo}
                </p>
              </div>
            )}
          </div>

          {/* {selectedTuzelDurum === "Özel" ? (
            <>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold mb-1"
                  htmlFor="Tc"
                >
                  TC Kimlik No
                </label>
                <input
                  type="text"
                  id="Tc"
                  name="Tc"
                  value={values.Tc}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-200 p-1 rounded-md"
                />
                {errors.Tc && touched.Tc && (
                  <div>
                    <p className="font-semibold text-xs text-red-color">
                      {errors.Tc}
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mb-4">
                <label
                  className="block text-sm font-semibold mb-1"
                  htmlFor="VergiNo"
                >
                  Vergi No
                </label>
                <input
                  type="text"
                  id="VergiNo"
                  name="VergiNo"
                  value={values.VergiNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border-2 border-gray-200 p-1 rounded-md"
                />
                {errors.VergiNo && touched.VergiNo && (
                  <div>
                    <p className="font-semibold text-xs text-red-color">
                      {errors.VergiNo}
                    </p>
                  </div>
                )}
              </div>
            </>
          )} */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="MusteriTip"
            >
              Müşteri Tür
            </label>
            <CariTipSelect
              options={musteriTipData}
              onChange={handleMusteriTipChange}
              defaultValue={currentCari.CariTipi}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
              Şehir
            </label>
            {sehirlerList.length != 0 && (
              <SehirSelect
                options={sehirlerList}
                onChange={handleSehirChange}
                addOperation={false}
                defaultValue={currentCari.Sehir}
              />
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
              İlçe
            </label>

            <IlceSelect
              options={ilcelerList}
              onChange={handleIlceChange}
              defaultValue={currentCari}
              defaultValueSehir={currentCari.Sehir}
              selectedSehirId={selectedSehirId}
              addOperation={false}
            />
          </div>

          <div className="mb-4">
            <div className="flex flex-col justify-center items-start">
              <label className="block text-sm font-semibold mr-4">
                Kullanıcı Oluşturulsun mu ?
              </label>
              <div className=" flex flex-row mt-3 ml-3">
                <input
                  type="radio"
                  id="evet"
                  name="selectedLoginDurum"
                  value="true"
                  checked={selectedLoginDurum === true}
                  onChange={handleLoginDurumChange}
                  className="mr-2"
                  disabled
                />
                <label htmlFor="evet" className="mr-4">
                  Evet
                </label>

                <input
                  type="radio"
                  id="hayir"
                  name="selectedLoginDurum"
                  value="false"
                  checked={selectedLoginDurum === false}
                  onChange={handleLoginDurumChange}
                  className="mr-2"
                  disabled
                />
                <label htmlFor="hayir" className="mr-4">
                  Hayır
                </label>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Adres">
              Adres
            </label>
            <textarea
              type="text"
              id="Adres"
              name="Adres"
              value={values.Adres}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              rows={3}
            />
            {errors.Adres && touched.Adres && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Adres}
                </p>
              </div>
            )}
          </div>

          <div className="md:col-span-2 col-span-1 flex justify-end">
            {currentCari.Durum == true ? (
              <button
                type="button"
                onClick={handleSilClick}
                className="bg-red-color text-white-color px-4 py-2 rounded-md hover:bg-opacity-80 mr-4"
              >
                Pasife Al
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSilClick}
                className="bg-green-color text-white-color px-4 py-2 rounded-md hover:bg-opacity-80 mr-4"
              >
                Aktife Al
              </button>
            )}

            <button
              type="submit"
              className="bg-green-color text-white-color px-4 py-2 rounded-md hover:bg-opacity-80"
            >
              Güncelle
            </button>
          </div>
        </form>
      </div>
      {/* Confirm modal'ı göstermek için */}
      {showConfirmModal && (
        <ConfirmModal
          possButtonText={
            currentsCari.Durum === true ? "Pasife Al" : "Aktife Al"
          }
          message={
            currentsCari.Durum === true
              ? "Bu Cariyi Pasif Hale Getirmek İstediğinizden Emin misiniz?"
              : "Bu Cariyi Aktif Hale Getirmek İstediğinizden Emin misiniz?"
          }
          onConfirm={
            currentsCari.Durum === true ? handleConfirmSil : handleConfirmAktif
          }
          onCancel={handleCancelSil}
        />
      )}
    </div>
  );
}

export default PopupUpdateCari;
